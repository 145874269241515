<template>
  <div>
    <!-- 要把数据分开不然会数据污染bug -->
    <!-- 直播列表 -->
    <el-table
      v-if="type == 0"
      :data="liveList"
      style="width: 100%"
      :header-cell-style="{
        background: '#EFF6FF',
        color: '#606266',
        fontSize: '14px',
        fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei',
        fontWeight: 'bold',
        color: '#333333',
      }"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        v-for="(item, index) in tableHeader"
        :key="index"
        :prop="item.val"
        :label="item.name"
        :width="item.width"
      >
      </el-table-column>
    </el-table>
    <!-- 录播 -->
    <el-table
      v-if="type == 1"
      :data="videoList"
      style="width: 100%"
      :header-cell-style="{
        background: '#EFF6FF',
        color: '#606266',
        fontSize: '14px',
        fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei',
        fontWeight: 'bold',
        color: '#333333',
      }"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        v-for="(item, index) in tableHeader"
        :key="index"
        :prop="item.val"
        :label="item.name"
        :width="item.width"
      >
      </el-table-column>
    </el-table>
    <!-- 考场 -->
    <el-table
      v-if="type == 2"
      :data="examList"
      style="width: 100%"
      :header-cell-style="{
        background: '#EFF6FF',
        color: '#606266',
        fontSize: '14px',
        fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei',
        fontWeight: 'bold',
        color: '#333333',
      }"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        v-for="(item, index) in tableHeader"
        :key="index"
        :prop="item.val"
        :label="item.name"
        :width="item.width"
      >
      </el-table-column>
    </el-table>
    <!-- 资料 -->
    <el-table
      v-if="type == 4"
      :data="dataList"
      style="width: 100%"
      :header-cell-style="{
        background: '#EFF6FF',
        color: '#606266',
        fontSize: '14px',
        fontFamily: 'Microsoft YaHei-Bold, Microsoft YaHei',
        fontWeight: 'bold',
        color: '#333333',
      }"
    >
      <el-table-column
        v-for="(item, index) in tableHeader"
        :show-overflow-tooltip="true"
        :key="index"
        :prop="item.val"
        :label="item.name"
        :width="item.width"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableHeader: [],
    };
  },
  props: {
    // 资料列表
    dataList: {
      type: Array,
      required: true,
      default: [],
    },
    // 录播列表
    videoList: {
      type: Array,
      required: true,
      default: [],
    },
    // 考场列表
    examList: {
      type: Array,
      required: true,
      default: [],
    },
    // 直播列表
    liveList: {
      type: Array,
      required: true,
      default: [],
    },
    type: {
      type: Number,
      required: true,
      default: 0,
    },
    tab: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  watch: {
    type: {
      deep: true,
      immediate: true,
      handler(val) {
        this.tableData = [];
        // 有的对应的字段名称不一样
        if (this.tab == 0 && this.type == 0) {
          this.tableHeader = [
            {
              name: "课程名称",
              val: "keName",
            },
            {
              name: "章名称",
              val: "chapterName",
            },

            {
              name: "开始时间",
              val: "recordingTime",
              width: "170",
            },
            {
              name: "结束时间",
              val: "endTime",
              width: "170",
            },
            {
              name: "学习时长",
              val: "studyTime",
            },
          ];
        } else if (this.tab == 0 && this.type == 1) {
          this.tableHeader = [
            {
              name: "课程名称",
              val: "keName",
            },
            {
              name: "章名称",
              val: "groupName",
            },
            {
              name: "节名称",
              val: "chapterName",
            },

            {
              name: "开始时间",
              val: "recordingTime",
              width: "170",
            },
            {
              name: "结束时间",
              val: "endTime",
              width: "170",
            },
            {
              name: "学习时长",
              val: "studyTime",
            },
          ];
        } else if (this.tab == 0 && this.type == 2) {
          this.tableHeader = [
            {
              name: "考场名称",
              val: "roomName",
            },
            {
              name: "班型名称",
              val: "className",
            },

            {
              name: "开始时间",
              val: "recordingTime",
              width: "170",
            },
            {
              name: "提交时间",
              val: "submitTime",
              width: "170",
            },
            {
              name: "考试时长",
              val: "answerTimes",
            },
          ];
        } else if (this.tab == 0 && this.type == 4) {
          this.tableHeader = [
            {
              name: "资料名称",
              val: "midName",
            },

            {
              name: "下载时间",
              val: "submitTime",
              width: "170",
            },
          ];
        } else if (this.tab == 1 && this.type == 0) {
          this.tableHeader = [
            {
              name: "课程名称",
              val: "keName",
            },
            {
              name: "章名称",
              val: "chapterName",
            },

            {
              name: "开始时间",
              val: "recordingTime",
              width: "170",
            },
            {
              name: "结束时间",
              val: "endTime",
              width: "170",
            },
            {
              name: "学习时长",
              val: "studyTime",
            },
          ];
        } else if (this.tab == 1 && this.type == 1) {
          this.tableHeader = [
            {
              name: "课程名称",
              val: "keName",
            },
            {
              name: "章名称",
              val: "groupName",
            },
            {
              name: "节名称",
              val: "chapterName",
            },

            {
              name: "开始时间",
              val: "recordingTime",
              width: "170",
            },
            {
              name: "结束时间",
              val: "endTime",
              width: "170",
            },
            {
              name: "学习时长",
              val: "studyTime",
            },
          ];
        } else if (this.tab == 1 && this.type == 4) {
          this.tableHeader = [
            {
              name: "资料名称",
              val: "midName",
            },
            {
              name: "下载时间",
              val: "submitTime",
              width: "170",
            },
          ];
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-tooltip {
  width: 100% !important;
}
</style>